import * as React from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

type SlideInfo = {
  linkUrl: string,
  imageUrl: string,
  alt: string,
}

type Props = {
  slides: SlideInfo[],
};

class BannerSlides extends React.Component<Props> {
  constructor (props: Props) {
    super(props);
  }

  render () {
    const { slides } = this.props
    const generateImgTag = (slideInfo) => {
      let imgTag = null;
      if (!slideInfo.linkUrl) {
        imgTag = <img src={slideInfo.imageUrl} alt={slideInfo.alt} />;
      } else {
        imgTag = (
          <a href={slideInfo.linkUrl}>
            <img src={slideInfo.imageUrl} alt={slideInfo.alt} />
          </a>
        );
      }
      return imgTag;
    };

    return (
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        navigation={true}
        loop={true}
        // centeredSlides={true}
        // breakpoints={{
        //   // NOTE: スライドの表示枚数：800px/1600px/2400px で切り替える場合
        //   800: { slidesPerView: 3 },
        //   1600: { slidesPerView: 5 },
        //   2400: { slidesPerView: 7 },
        // }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {slides.map((si, idx) => {
          return <SwiperSlide key={idx}>{generateImgTag(si)}</SwiperSlide>;
        })}
      </Swiper>
    );
  }
};

export default BannerSlides;
